import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/SEO'

import aanbodStyles from '../css/aanbod.module.css'
import AanbodDetail from '../components/AanbodDetail'
import { aanbodData } from '../data/aanbodData.js'

const Aanbod = props => {
	if (props.location && props.location.state && props.location.state.data) {
		return <AanbodDetail data={props.location.state.data}></AanbodDetail>
	} else {
		return (
			<Layout>
				<SEO title="Aanbod" />
				<h1>
					Bij Kine+ Kids kunnen kinderen terecht voor verschillende
					specialisaties binnen de pediatrische kinesitherapie.
				</h1>
				<div className={aanbodStyles.container}>
					{aanbodData.map(element => {
						return (
							<Link
								key={element.id}
								to={`/aanbod/#${element.title}`}
								state={{ data: element }}
								className={aanbodStyles.aanbodItem}
							>
								<img
									src={element.path}
									alt="aanbod"
									width="270"
									height="335"
								></img>
							</Link>
						)
					})}
				</div>
			</Layout>
		)
	}
}

export default Aanbod
