import React, { useEffect } from 'react'
import { Link } from 'gatsby'

import Layout from './layout'
import SEO from './SEO'

import aanbodStyles from '../css/aanbod.module.css'
import '../css/global.css'

const AanbodDetail = props => {
	useEffect(() => {
		if (props.data.content) {
			const contentEl = document.querySelector(`.${aanbodStyles.peetje}`)
			contentEl.innerHTML = props.data.content
		}
	})

	const renderList = () => (
		<div className={aanbodStyles.list}>
			<p className={aanbodStyles.intro}>Toegepast bij kinderen met:</p>

			<ul>
				{props.data.list.map((element, index) => {
					return (
						<li className={aanbodStyles.listItem} key={index}>
							{element.toString()}
						</li>
					)
				})}
			</ul>
		</div>
	)

	return (
		<Layout>
			<SEO title="Aanbod" />
			<h1>{props.data.title}</h1>
			{props.data.list ? renderList() : null}
			<img
				className={aanbodStyles.detailImage}
				src={props.data.path}
				alt={props.data.title}
				width="220"
			></img>
			<p className={aanbodStyles.peetje}></p>
			<Link to="/aanbod" className={aanbodStyles.link}>
				TERUG
			</Link>
		</Layout>
	)
}

export default AanbodDetail
